import 'bootstrap';
import 'jquery-lazy';
import 'assets/styles/app.scss';
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import 'slick-carousel';

const loadedComponents = [];

document.addEventListener( 'DOMContentLoaded', async () => {
	/**
	 * Bootstrap the components
	 */
	for ( const dataComponent of document.querySelectorAll( '[data-component]' ) ) {
		let componentName = dataComponent.dataset.component;

		if ( loadedComponents.indexOf( componentName ) === - 1 ) {
			loadedComponents.push( componentName );

			try {
				let component = await import(
					/* WebpackMode: "lazy" */
					/* webpackPrefetch: true */
					/* webpackPreload: true */
					`components/${componentName}/${componentName}.js`
					);

				component.default();

			} catch ( error ) {
				console.error( 'Loading error: ', error );
			}
		}
	}

	$(document).on( 'click', 'button.plus, button.minus', function(e) {
		e.preventDefault();
		var qty = $( this ).parent( '.quantity' ).find( '.qty' );
		var val = parseFloat(qty.val());
		var max = parseFloat(qty.attr( 'max' ));
		var min = parseFloat(qty.attr( 'min' ));
		var step = parseFloat(qty.attr( 'step' ));
		if ( $( this ).is( '.plus' ) ) {
			if ( max && ( max <= val ) ) {
				qty.val( max ).change();
			} else {
				qty.val( val + step ).change();
			}
		} else {
			if ( min && ( min >= val ) ) {
				qty.val( min ).change();
			} else if ( val > 1 ) {
				qty.val( val - step ).change();
			}
		}
	});

	$(document.body).trigger('wc_fragment_refresh');
	$('[data-bs-toggle="tooltip"]').tooltip()

	//js-set-slider
	if($('.js-set-slider').length) {
		const rel_a_settings = {
			rows: 0,
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			arrows: true,
			prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><i class="fa fa-arrow-left"></i></button>',
			nextArrow: '<button class="slick-next" aria-label="Next" type="button"><i class="fa fa-arrow-right"></i></button>',
			dots: false,
			swipeToSlide: true,
			infinite: true,
			responsive: [
				{
					breakpoint: 1024,
					settings: "unslick"
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			],
		};

		const $rel_a_slider = jQuery('.js-set-slider .slider-group');
		const rel_a_sl =  $rel_a_slider.slick(rel_a_settings);

		if( !rel_a_sl.hasClass('slick-initialized')) {
			$rel_a_slider.slick( rel_a_settings );
		}

		jQuery(window).on('resize', function() {
			if( !rel_a_sl.hasClass('slick-initialized')) {
				$rel_a_slider.slick(rel_a_settings);
			}
		});

		window.dispatchEvent(new Event('resize'));
	}

} );
